import React from 'react';
import classnames from 'classnames';
import ThumbsUp from '../../../svgs/thumb-up.svg';

type Props = {
  message: string;
};

function ReinforcementElement(props: Props) {
  const thumbClassName = classnames('ReinforcementElement-thumb', {
    'ReinforcementElement-thumb--birthday': props.message === ReinforcementElement.MESSAGES.BIRTHDAY,
    'ReinforcementElement-thumb--benefit-age-limit': props.message === ReinforcementElement.MESSAGES.BENEFIT_AGE_LIMIT,
  });

  return (
    <div className="ReinforcementElement">
      <ThumbsUp className={thumbClassName} />
      <div className="ReinforcementElement-message">
        {props.message}
      </div>
    </div>
  );
}

ReinforcementElement.MESSAGES = {
  BIRTHDAY: 'Gute Entscheidung! Sie kümmern sich frühzeitig und sichern sich somit ' +
    'günstige Beiträge über die Laufzeit.',
  BENEFIT_AGE_LIMIT: 'Gute Wahl! Damit sind Sie bis zum Eintritt in die Rente abgesichert.'
};

export default ReinforcementElement;