import { useState, useCallback } from 'react';
import { PUBLIC_SERVANT_PAY_GRADE, ValueOf } from 'bu.lookups';
import { PAYGRADE_MAP } from '../../constants/public-servant-pay-grade.constant';

export function usePublicServantPaygrade(
  onChange: (input: ValueOf<typeof PUBLIC_SERVANT_PAY_GRADE> | undefined)=>void,
  payGrade: ValueOf<typeof PUBLIC_SERVANT_PAY_GRADE> | undefined
) {
  const [letter, setLetter] =
    useState<keyof typeof PAYGRADE_MAP>(
      payGrade ?
        payGrade.substring(0, 1) as keyof typeof PAYGRADE_MAP :
        Object.keys(PAYGRADE_MAP)[0] as keyof typeof PAYGRADE_MAP
    );
  const [number, setNumber] = useState(payGrade ? payGrade.replace(letter, '') : '');

  const updateNumber = useCallback(
    (numberInput: string) => {
      setNumber(numberInput);
      if (numberInput !== '') {
        onChange(`${letter}${numberInput}` as ValueOf<typeof PUBLIC_SERVANT_PAY_GRADE>);
      } else {
        onChange(undefined);
      }
    },
    [letter, onChange, setNumber]
  );

  const updateLetter = useCallback(
    (letterInput: keyof typeof PAYGRADE_MAP) => { setLetter(letterInput); setNumber(''); onChange(undefined); },
    [setLetter, setNumber, onChange]
  );
  return { letter, updateLetter, number, updateNumber };
}
