import { SEARCH_DATA_FIELDS } from 'bu.lookups';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { DATE_FORMAT } from 'bu.formatters';
import * as ERROR_MESSAGES from '../../constants/error-messages.constant';
import { validatePublicServantEnteredServiceDate } from '../../helpers/duv.helper';
import storeFactory from '../../factories/store.factory';

export function usePublicServantEnteredServiceDate(initialPublicServantEnteredServiceDate?: string) {
  const onboardingValidationStore = storeFactory.getOnboardingValidationStore();
  const searchDataStore = storeFactory.getSearchDataStore();

  const [errorMessage, setErrorMessage] = useState(
    onboardingValidationStore.publicServantEnteredServiceDate.getErrorMessageIfAny()
  );
  const [publicServantEnteredServiceDate, setPublicServantEnteredServiceDate] = useState('');

  useEffect(() => {
    setErrorMessage(onboardingValidationStore.publicServantEnteredServiceDate.getErrorMessageIfAny());
  }, [onboardingValidationStore.publicServantEnteredServiceDate]);

  useEffect(() => {
    const { valid } = validatePublicServantEnteredServiceDate(
      searchDataStore.searchData[SEARCH_DATA_FIELDS.JOB_SITUATION],
      initialPublicServantEnteredServiceDate
    );

    if (valid) {
      setPublicServantEnteredServiceDate(
        moment(initialPublicServantEnteredServiceDate).format(DATE_FORMAT.MONTH_YEAR_SHORT)
      );
    }
  }, [initialPublicServantEnteredServiceDate, searchDataStore.searchData]);

  const updatePublicServantEnteredServiceDate = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const germanFormattedValue = moment(event.target.value, DATE_FORMAT.MONTH_YEAR_SHORT, true);

    const isoFormattedValue
      = germanFormattedValue.isValid() ? germanFormattedValue.format(DATE_FORMAT.ISO_8601_DATE) : event.target.value;

    const { valid, status } = validatePublicServantEnteredServiceDate(
      searchDataStore.searchData[SEARCH_DATA_FIELDS.JOB_SITUATION],
      isoFormattedValue
    );

    if (valid) {
      setErrorMessage('');
      searchDataStore.updateSearchData({
        [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE]: isoFormattedValue
      });
    } else {
      setErrorMessage(ERROR_MESSAGES.PUBLIC_SERVANT_ENTERED_SERVICE_DATE[status]);
      searchDataStore.updateSearchData({ [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE]: '' });
    }
  };
  
  return {
    publicServantEnteredServiceDate,
    errorMessage,
    setPublicServantEnteredServiceDate,
    updatePublicServantEnteredServiceDate
  };
}
