import { INDUSTRIES } from 'bu.lookups';

import occupationService from './occupation.service';

import searchDataOptionsFactory from '../factories/search-data-options.factory';
import storeFactory from '../factories/store.factory';
import searchDataFactory from '../factories/search-data.factory';

class OnboardingRecommendationService {
  findRecommendationIndustry(industry: string): string {
    const optionalOptions = searchDataOptionsFactory.searchDataOptions.optionalOptions;
    const industryOptions = optionalOptions.industry.map((i) => i.value);

    return industryOptions.find((option) => option === industry) || INDUSTRIES.SONSTIGE_BRANCHE;
  }

  fetchRecommendations() {
    const searchData = storeFactory.getSearchDataStore().searchData;
    return occupationService.getOccupation(searchData.occupation, searchData.jobSituation, searchData.wpSet);
  }

  mapFractionOfficeWork(officeWork: number) {
    const updatedOfficeWorkValue = officeWork === 80 ? 100 : officeWork;
    const optionalOptions = searchDataOptionsFactory.searchDataOptions.optionalOptions;
    const officeWorkOption = optionalOptions.fractionOfficeWork.find(
      (option) => option.value === updatedOfficeWorkValue);
    return officeWorkOption ? officeWorkOption.value : searchDataFactory.createDefaultSearchData().fractionOfficeWork;
  }
}

export default new OnboardingRecommendationService();
