import React from 'react';
import { observer } from 'mobx-react';
import {
  PUBLIC_SERVANT_JOB_SITUATIONS_VALUE,
  PUBLIC_SERVANT_JOB_SITUATIONS_TEXT,
  SEARCH_DATA_FIELDS,
  PUBLIC_SERVANT_CAREER_GROUP_TEXT, ValueOf
} from 'bu.lookups';
import { Mobile } from 'bu.components-customer-apps';

import onboardingHelpTexts from './onboarding-help-texts';
import storeFactory from '../../factories/store.factory';
import PublicServantPayGrade from './public-servant-paygrade';
import {
  usePublicServantLifetimeAppointmentDate
} from '../../hooks/onboarding/use-public-servant-lifetime-appointment-date.hook';
import {
  usePublicServantEnteredServiceDate
} from '../../hooks/onboarding/use-public-servant-entered-service-date.hook';

const careerGroupInputOptions = Object.entries(PUBLIC_SERVANT_CAREER_GROUP_TEXT)
  .map(([value, text]) => ({ value, text })) as {
    value: keyof typeof PUBLIC_SERVANT_CAREER_GROUP_TEXT;
    text: ValueOf<typeof PUBLIC_SERVANT_CAREER_GROUP_TEXT>;
  }[];

function OnboardingBeamtetSection() {
  const searchDataStore = storeFactory.getSearchDataStore();
  const onboardingValidationStore = storeFactory.getOnboardingValidationStore();

  const {
    publicServantLifetimeAppointmentDate,
    errorMessage: publicServantLifetimeAppointmentDateErrorMessage,
    setPublicServantLifetimeAppointmentDate,
    updatePublicServantLifetimeAppointmentDate
  } = usePublicServantLifetimeAppointmentDate(
    searchDataStore.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE],
  );

  const {
    publicServantEnteredServiceDate,
    errorMessage: publicServantEnteredServiceDateErrorMessage,
    setPublicServantEnteredServiceDate,
    updatePublicServantEnteredServiceDate
  } = usePublicServantEnteredServiceDate(
    searchDataStore.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE]
  );

  const jobSituationValue = searchDataStore.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION];

  return (
    <React.Fragment>
      <div className="Onboarding-header">Ihre Laufbahn</div>
      <div className="OnboardingPersonalDataSection-row">
        <Mobile.SelectBox
          label="Berufliche Situation (genau)"
          options={Object.entries(PUBLIC_SERVANT_JOB_SITUATIONS_TEXT).map(([key, value]) => (
            { value: key as keyof typeof PUBLIC_SERVANT_JOB_SITUATIONS_TEXT,
              text: value as ValueOf<typeof PUBLIC_SERVANT_JOB_SITUATIONS_TEXT> }
          ))}
          value={jobSituationValue}
          onChange={value => {
            searchDataStore.updateSearchData({ [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION]: value });
            onboardingValidationStore.validatePublicServantJobSituation();
          }}
          modalContent={onboardingHelpTexts[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_JOB_SITUATION].content}
          errorMessage={onboardingValidationStore.publicServantJobSituation.getErrorMessageIfAny()}
          dataTestId="publicServantJobSituation"
          dataTestError="publicServantJobSituationError"
          pleaseSelectText="Bitte wählen"
        />
      </div>
      <div className="OnboardingPersonalDataSection-row">
        <Mobile.SelectBox
          label="Laufbahngruppe"
          options={careerGroupInputOptions}
          value={searchDataStore.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_CAREER_GROUP]}
          onChange={value => {
            searchDataStore.updateSearchData({ [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_CAREER_GROUP]: value });
            onboardingValidationStore.validatePublicServantCareerGroup();
          }}
          modalContent={onboardingHelpTexts[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_CAREER_GROUP].content}
          errorMessage={onboardingValidationStore.publicServantCareerGroup.getErrorMessageIfAny()}
          dataTestId="publicServantCareerGroup"
          dataTestError="publicServantCareerGroupError"
          pleaseSelectText="Bitte wählen"
        />
      </div>
      <div className="OnboardingPersonalDataSection-row">
        <Mobile.FormInput
          placeholder="Bitte eingeben"
          label="Anerkannte Erfahrungsjahre bei Diensteintritt"
          /*
            We can't use null or undefined for empty values because react considers them as uncontrolled inputs
            otherwise, and when value changes to a defined one, change to controlled input happens which causes
            react to show a warning. So we use empty string instead.
          */
          value={searchDataStore.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_EXPERIENCE_YEARS] ?? ''}
          onChange={value => {
            searchDataStore.updateSearchData({ [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_EXPERIENCE_YEARS]: Number(value) });
            onboardingValidationStore.validatePublicServantExperienceYears();
          }}
          errorMessage={onboardingValidationStore.publicServantExperienceYears.getErrorMessageIfAny()}
          modalContent={onboardingHelpTexts[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_EXPERIENCE_YEARS].content}
          type="tel"
          allowedKeys="0123456789"
          dataTestId="publicServantExperienceYears"
          dataTestError="publicServantExperienceYearsError"
          showLabelOnlyWhenFilled={false}
        />
      </div>
      <div className="OnboardingPersonalDataSection-row">
        <Mobile.FormInput
          label="Diensteintritt"
          value={publicServantEnteredServiceDate}
          onChange={setPublicServantEnteredServiceDate}
          onBlur={updatePublicServantEnteredServiceDate}
          errorMessage={publicServantEnteredServiceDateErrorMessage}
          modalContent={onboardingHelpTexts[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_ENTERED_SERVICE_DATE].content}
          type="tel"
          mask="11/1111"
          placeholder="MM/JJJJ"
          allowedKeys="0123456789."
          dataTestId="publicServantEnteredServiceDate"
          dataTestError="publicServantEnteredServiceDateError"
          showLabelOnlyWhenFilled={false}
        />
      </div>
      <div className="OnboardingPersonalDataSection-row">
        <Mobile.FormInput
          label="Verbeamtung auf Lebenszeit"
          onChange={setPublicServantLifetimeAppointmentDate}
          value={publicServantLifetimeAppointmentDate}
          onBlur={updatePublicServantLifetimeAppointmentDate}
          errorMessage={publicServantLifetimeAppointmentDateErrorMessage}
          modalContent={onboardingHelpTexts[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE].content}
          type="tel"
          mask="11/1111"
          placeholder="MM/JJJJ"
          allowedKeys="0123456789."
          dataTestId="publicServantLifetimeAppointmentDate"
          dataTestError="publicServantLifetimeAppointmentDateError"
          disabled={jobSituationValue === PUBLIC_SERVANT_JOB_SITUATIONS_VALUE.BEAMTET_AUF_WIDERRUF}
          showLabelOnlyWhenFilled={false}
        />
      </div>
      <PublicServantPayGrade
        onChange={(value) => {
          if (value !== searchDataStore.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_PAY_GRADE]) {
            searchDataStore.updateSearchData({ [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_PAY_GRADE]: value });
            if (onboardingValidationStore.publicServantPayGrade.getErrorMessageIfAny()) {
              onboardingValidationStore.validatePublicServantPayGrade();
            }
          }
        }}
        payGrade={searchDataStore.searchData[SEARCH_DATA_FIELDS.PUBLIC_SERVANT_PAY_GRADE]}
        errorMessage={onboardingValidationStore.publicServantPayGrade.getErrorMessageIfAny()}
      />
    </React.Fragment>
  );
}

export default observer(OnboardingBeamtetSection);
