import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { SEARCH_DATA_FIELDS } from 'bu.lookups';
import { DATE_FORMAT } from 'bu.formatters';
import { isAgeBetween, validateBirthday } from '../../helpers/birthday.helper';

import * as ERROR_MESSAGES from '../../constants/error-messages.constant';
import BIRTHDAY_VALIDATION_STATUS from '../../constants/birthday-validation-status.constant';
import { MIN_AGE, MAX_AGE } from '../../constants/onboarding.constant';
import storeFactory from '../../factories/store.factory';

export function useOnboardingBirthdayInput(initialBirthday: string) {
  const searchDataStore = storeFactory.getSearchDataStore();
  const onboardingValidationStore = storeFactory.getOnboardingValidationStore();

  const [errorMessage, setErrorMessage] = useState(onboardingValidationStore.birthday.getErrorMessageIfAny());
  const [showBirthdayReinforcement, setShowBirthdayReinforcement] = useState(false);
  const [birthday, setBirthday] = useState('');

  useEffect(() => {
    setErrorMessage(onboardingValidationStore.birthday.getErrorMessageIfAny());
  }, [onboardingValidationStore.birthday]);

  useEffect(() => {
    const { valid, status } = validateBirthday(initialBirthday);

    if (
      valid ||
      BIRTHDAY_VALIDATION_STATUS.MAX_AGE_VIOLATED === status ||
      BIRTHDAY_VALIDATION_STATUS.MIN_AGE_VIOLATED === status
    ) {
      setBirthday(moment(initialBirthday).format(DATE_FORMAT.GERMAN_DATE));
      setErrorMessage(status ? ERROR_MESSAGES.INVALID_BIRTHDAY[status] : '');
    }

    setShowBirthdayReinforcement(valid && isAgeBetween(initialBirthday, MAX_AGE, MIN_AGE));

  }, [initialBirthday, setShowBirthdayReinforcement]);

  const updateBirthday = (event: React.FocusEvent<HTMLInputElement>) => {
    const germanFormattedBirthday = moment(event.target.value, DATE_FORMAT.GERMAN_DATE, true);

    const formattedBirthday = germanFormattedBirthday.isValid() ?
      germanFormattedBirthday.format(DATE_FORMAT.ISO_8601_DATE) : event.target.value;

    const { valid, status } = validateBirthday(formattedBirthday);

    if (
      valid ||
      BIRTHDAY_VALIDATION_STATUS.MAX_AGE_VIOLATED === status ||
      BIRTHDAY_VALIDATION_STATUS.MIN_AGE_VIOLATED === status
    ) {
      searchDataStore.updateSearchData({ [SEARCH_DATA_FIELDS.BIRTHDAY]: formattedBirthday });
      setErrorMessage(status ? ERROR_MESSAGES.INVALID_BIRTHDAY[status] : '');
    } else {
      setErrorMessage(ERROR_MESSAGES.INVALID_BIRTHDAY[status]);
      searchDataStore.updateSearchData({ [SEARCH_DATA_FIELDS.BIRTHDAY]: '' });
    }
  };

  return { birthday, errorMessage, setBirthday, updateBirthday, showBirthdayReinforcement };
}
