import { SEARCH_DATA_FIELDS } from 'bu.lookups';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { DATE_FORMAT } from 'bu.formatters';
import * as ERROR_MESSAGES from '../../constants/error-messages.constant';
import storeFactory from '../../factories/store.factory';
import { validatePublicServantLifetimeAppointmentDate } from '../../helpers/duv.helper';
import {
  PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE_VALIDATION_STATUS
} from '../../constants/public-servant-lifetime-appointment-date.constant';

export function usePublicServantLifetimeAppointmentDate(initialPublicServantLifetimeAppointmentDate?: string) {
  const searchDataStore = storeFactory.getSearchDataStore();
  const onboardingValidationStore = storeFactory.getOnboardingValidationStore();

  const [errorMessage, setErrorMessage] = useState(
    onboardingValidationStore.publicServantLifetimeAppointmentDate.getErrorMessageIfAny()
  );

  const [publicServantLifetimeAppointmentDate, setPublicServantLifetimeAppointmentDate] = useState('');

  useEffect(() => {
    setErrorMessage(onboardingValidationStore.publicServantLifetimeAppointmentDate.getErrorMessageIfAny());
  }, [onboardingValidationStore.publicServantLifetimeAppointmentDate]);

  useEffect(() => {
    const { valid, status } = validatePublicServantLifetimeAppointmentDate({
      ...searchDataStore.searchData,
      [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE]:
      initialPublicServantLifetimeAppointmentDate
    });

    if (
      valid ||
      status === PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE_VALIDATION_STATUS
        .OVER_PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE_VIOLATED
    ) {
      setPublicServantLifetimeAppointmentDate(
        moment(initialPublicServantLifetimeAppointmentDate).format(DATE_FORMAT.MONTH_YEAR_SHORT)
      );
      setErrorMessage(status ? ERROR_MESSAGES.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE[status] : '');
    }
  }, [initialPublicServantLifetimeAppointmentDate, searchDataStore.searchData]);

  const updatePublicServantLifetimeAppointmentDate = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const germanShortDate = moment(event.target.value, DATE_FORMAT.MONTH_YEAR_SHORT, true);

    const isoFormattedValue = germanShortDate.isValid()
      ? germanShortDate.date(1).format(DATE_FORMAT.ISO_8601_DATE)
      : event.target.value;

    const { valid, status } = validatePublicServantLifetimeAppointmentDate({
      ...searchDataStore.searchData,
      [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE]: isoFormattedValue
    });

    if (
      valid ||
      status === PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE_VALIDATION_STATUS
        .OVER_PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE_VIOLATED
    ) {
      setErrorMessage(status ? ERROR_MESSAGES.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE[status] : '');
      searchDataStore.updateSearchData({
        [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE]: isoFormattedValue
      });
    } else {
      setErrorMessage(ERROR_MESSAGES.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE[status]);
      searchDataStore.updateSearchData({ [SEARCH_DATA_FIELDS.PUBLIC_SERVANT_LIFETIME_APPOINTMENT_DATE]: '' });
    }
  };

  return {
    publicServantLifetimeAppointmentDate,
    errorMessage,
    setPublicServantLifetimeAppointmentDate,
    updatePublicServantLifetimeAppointmentDate
  };
}
