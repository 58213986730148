import React from 'react';
import { Mobile } from 'bu.components-customer-apps';
import { PUBLIC_SERVANT_PAY_GRADE, ValueOf } from 'bu.lookups';
import { usePublicServantPaygrade } from '../../hooks/onboarding/use-public-servant-paygrade.hook';
import { PAYGRADE_MAP } from '../../constants/public-servant-pay-grade.constant';

type Props = {
  onChange: (value: ValueOf<typeof PUBLIC_SERVANT_PAY_GRADE> | undefined) => void;
  errorMessage: string;
  payGrade: ValueOf<typeof PUBLIC_SERVANT_PAY_GRADE> | undefined ;
};
function PublicServantPayGrade({
  onChange,
  payGrade,
  errorMessage
}: Props) {
  const { letter, updateLetter, number, updateNumber } = usePublicServantPaygrade(onChange, payGrade);

  return (
    <React.Fragment>
      <div className="Onboarding-header">Besoldungsgruppe</div>
      <div className="PublicServantPayGradeSelector">
        <div>
          <Mobile.SelectBox
            label="Buchstabe"
            options={Object.keys(PAYGRADE_MAP).map((value) => (
              { value: value as keyof typeof PAYGRADE_MAP, text: value })
            )}
            value={letter}
            onChange={updateLetter}
            dataTestId="publicServantBuchstabe"
            dataTestError="publicServantBuchstabeError"
          />
        </div>
        <div>
          <Mobile.SelectBox
            label="Ziffer"
            options={[
              { text: 'Bitte wählen', value: '' },
              ...PAYGRADE_MAP[letter].map((value) => ({
                value,
                text: String(value)
              }))
            ]}
            value={number}
            onChange={updateNumber}
            errorMessage={errorMessage}
            dataTestId="publicServantZiffer"
            dataTestError="publicServantZifferError"
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default PublicServantPayGrade;
