import React from 'react';
import { Mobile } from 'bu.components-customer-apps';

import onboardingHelpTexts from './onboarding-help-texts';
import ReinforcementElement from '../common/reinforcement-element/reinforcement-element';
import { useOnboardingBirthdayInput } from '../../hooks/onboarding/use-onboarding-birthday-input.hook';

type Props = {
  initialBirthday: string;
};

function OnboardingBirthday({ initialBirthday }: Props) {
  const {
    birthday,
    errorMessage,
    setBirthday,
    updateBirthday,
    showBirthdayReinforcement
  } = useOnboardingBirthdayInput(initialBirthday);

  return (
    <div className="OnboardingBirthday-birthdayRow">
      <div className="Onboarding-header">Ihr Geburtsdatum</div>
      <Mobile.BirthdayInput
        value={birthday}
        errorMessage={errorMessage}
        onChange={setBirthday}
        onBlur={updateBirthday}
        modalContent={onboardingHelpTexts.birthday.content}
        dataTestId="birthday"
        dataTestError="birthdayError"
      />
      {showBirthdayReinforcement &&
        <ReinforcementElement message={ReinforcementElement.MESSAGES.BIRTHDAY} />}
    </div>
  );
}

export default OnboardingBirthday;
