import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { Mobile } from 'bu.components-customer-apps';

import { SEARCH_DATA_FIELDS } from 'bu.lookups';
import searchDataOptionsFactory from '../../factories/search-data-options.factory';
import searchDataFactory from '../../factories/search-data.factory';
import onboardingHelpTexts from './onboarding-help-texts';
import storeFactory from '../../factories/store.factory';
import onboardingRecommendationService from '../../services/onboarding-recommendation.service';
import occupationService from '../../services/occupation.service';
import { canShowGfBanner } from '../../helpers/gf-banner.helper';
import OnboardingBirthday from './onboarding-birthday';

import ResetSvg from '../../svgs/reset.svg';
import CrossSvg from '../../svgs/cross.svg';
import OnboardingBeamtetSection from './onboarding-beamtet-section';
import { isDUVapplicable } from '../../helpers/duv.helper';
import { Occupation } from '../../types/occupation';
import { SearchData } from '../../types/search-data';

function OnboardingPersonalDataSection() {
  const searchDataStore = storeFactory.getSearchDataStore();
  const onboardingValidationStore = storeFactory.getOnboardingValidationStore();
  const gfBannerStore = storeFactory.getGfBannerStore();
  const { mandatoryOptions } = searchDataOptionsFactory.searchDataOptions;

  const [occupation, setOccupation] = useState(searchDataStore.searchData.occupation);
  const [occupations, setOccupations] = useState<string[]>([]);
  const [showOccupationRecallHint, setShowOccupationRecallHint] = useState(false);
  const [initialOccupation, setInitialOccupation] = useState('');

  useEffect(() => {
    setInitialOccupation(searchDataStore.searchData.occupation);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateRecommendedSearchData = (recommendation: Occupation, additionalFieldsToUpdate?: Partial<SearchData>) => {
    const recommendationSearchData = {
      fractionOfficeWork: onboardingRecommendationService.mapFractionOfficeWork(recommendation.fractionOfficeWork),
      benefitAmount: recommendation.benefitAmount,
      benefitAgeLimit: recommendation.benefitAgeLimit,
      industry: onboardingRecommendationService.findRecommendationIndustry(recommendation.industry)
    };

    action(() => {
      Object.assign(searchDataStore.searchData, recommendationSearchData, additionalFieldsToUpdate);
    })();
  };

  const onOccupationChange = async () => {
    const isOccupationValid = await onboardingValidationStore.validateOccupation();

    if (!isOccupationValid) {
      return;
    }

    if (!initialOccupation) {
      const recommendations = await onboardingRecommendationService.fetchRecommendations();

      if (recommendations) {
        updateRecommendedSearchData(recommendations);
      }
    }

    setShowOccupationRecallHint(
      Boolean(initialOccupation && initialOccupation !== searchDataStore.searchData.occupation)
    );
  };

  const resetDefaultRecommendation = async () => {
    const recommendations = await onboardingRecommendationService.fetchRecommendations();
    const defaultSearchData = searchDataFactory.createDefaultSearchData();
    const additionalFieldsToUpdate = {
      excellentIncreaseOptionWithReasonDesired: defaultSearchData.excellentIncreaseOptionWithReasonDesired,
      excellentSustainabilityDesired: defaultSearchData.excellentSustainabilityDesired,
      excellentStiftungWarentest: defaultSearchData.excellentStiftungWarentest,
      excellentCertifiedSickness: defaultSearchData.excellentCertifiedSickness,
      dynamicPremiumFilter: defaultSearchData.dynamicPremiumFilter,
      tariffGradeFilter: defaultSearchData.tariffGradeFilter,
      staffResponsibility: defaultSearchData.staffResponsibility
    };

    updateRecommendedSearchData(recommendations, additionalFieldsToUpdate);

    setShowOccupationRecallHint(false);
  };

  const renderJobSituation = (
    <div className="OnboardingPersonalDataSection-row">
      <Mobile.SelectBox
        options={mandatoryOptions.jobSituation}
        onChange={value => {
          searchDataStore.updateSearchData({ [SEARCH_DATA_FIELDS.JOB_SITUATION]: value });
          onboardingValidationStore.validateJobSituation();
        }}
        modalContent={onboardingHelpTexts.jobSituation.content}
        errorMessage={onboardingValidationStore.jobSituation.getErrorMessageIfAny()}
        dataTestError="jobSituationError"
        dataTestId="jobSituation"
        value={searchDataStore.searchData.jobSituation}
        label="Berufliche Situation"
      />
    </div>
  );

  const renderOccupation = (
    <div className="OnboardingPersonalDataSection-row OnboardingPersonalDataSection-space">
      <Mobile.InputAutoSuggestModal
        label="Beruf"
        placeholder="Bitte aktuellen Beruf eintippen"
        dataTestId="occupation"
        dataTestError="occupationError"
        errorMessage={onboardingValidationStore.occupation.getErrorMessageIfAny()}
        modalContent={onboardingHelpTexts.occupation.content}
        fetchOccupations={async occupationInput => {
          const result = await occupationService.debouncedSuggest(occupationInput) ?? [];
          setOccupations(result);
          return result;
        }}
        onChange={value => {
          setOccupation(value);
        }}
        value={occupation}
        suggestionSelected={value => {
          setOccupation(value);
          searchDataStore.updateSearchData({ [SEARCH_DATA_FIELDS.OCCUPATION]: value });
          onOccupationChange();
        }}
        onModalClose={() => {
          const occupationValue = searchDataStore.searchData.occupation || occupations[0] || (occupation || '').trim();
          setOccupation(occupationValue);

          searchDataStore.updateSearchData({
            [SEARCH_DATA_FIELDS.OCCUPATION]: occupationValue
          });
          onboardingValidationStore.validateOccupation();
        }}
      />
    </div>
  );

  const renderEducationType = (
    <div className="OnboardingPersonalDataSection-row">
      <Mobile.SelectBox
        label="Höchster Bildungsabschluss"
        options={mandatoryOptions.educationType}
        value={searchDataStore.searchData.educationType}
        onChange={value => {
          searchDataStore.updateSearchData({ [SEARCH_DATA_FIELDS.EDUCATION_TYPE]: value });
          onboardingValidationStore.validateEducationType();
          if (!canShowGfBanner(searchDataStore.searchData)) {
            gfBannerStore.resetGfBannerState();
          }
        }}
        modalContent={onboardingHelpTexts.educationType.content}
        errorMessage={onboardingValidationStore.educationType.getErrorMessageIfAny()}
        dataTestId="education"
        dataTestError="educationError"
        pleaseSelectText="Bitte wählen"
      />
    </div>
  );

  const renderOccupationRecallHint = (
    <div className="OnboardingPersonalDataSection-recalcHint">
      <ResetSvg className="OnboardingPersonalDataSection-recalcHintIcon" />
      <div>
        <div className="OnboardingPersonalDataSection-recalcHintHeadlineText">
          Sie haben den Beruf geändert.
        </div>
        Möchten Sie einen neuen Vergleich starten und Ihre bisherigen
        <a onClick={resetDefaultRecommendation}> Einstellungen aktualisieren?</a>
      </div>
      <CrossSvg className="OnboardingPersonalDataSection-recalcHintClose"
        onClick={() => setShowOccupationRecallHint(false)} />
    </div>
  );

  return (
    <React.Fragment>
      <OnboardingBirthday initialBirthday={searchDataStore.searchData.birthday} />
      <div className="Onboarding-header">Ihre berufliche Situation</div>
      {renderEducationType}
      {renderJobSituation}
      {renderOccupation}
      {showOccupationRecallHint && renderOccupationRecallHint}
      {
        isDUVapplicable(searchDataStore.searchData[SEARCH_DATA_FIELDS.JOB_SITUATION])
        && <OnboardingBeamtetSection />
      }
    </React.Fragment>
  );
}

export default observer(OnboardingPersonalDataSection);
